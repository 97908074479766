import React from 'react';
import './founders.scss';
import { FaLinkedin } from 'react-icons/fa';

const Dorothy = () => {

    return (
        <div className='susanWrapper'>

            <div className="wrapper">
                <div className="top">
                    <img className='imageOne' src={process.env.PUBLIC_URL + '/rothy.png'} alt="" />

                    <div className="text">
                        <h3>Dorothy Nandinghi</h3>
                        <span>Co-founder & CFO</span>
                        <a
                            href="https://www.linkedin.com/in/dorothy-nandinghi-2b32bb123/"
                            target="_blank" rel="noopener noreferrer"
                            className='link'
                        >
                            < FaLinkedin className="founderIcon" />
                        </a>
                    </div>
                </div>
                <div className="bottom">
                    <span>
                        Meet Dorothy Nandinghi, the Co-founder and CFO of Regonow. As a seasoned finance expert,
                        Dorothy brings strategic acumen and analytical prowess to drive business growth and
                        financial excellence.

                    </span>

                    <span>
                        With a strong educational background in Quantitative
                        Economics (BSc, Makerere University) and Economic Research (MSc, University of North Texas),
                        she leverages her experience at the Federal National Mortgage Association (Fannie Mae, Texas, USA)
                        to steer Regonow's financial strategy and achieve outstanding results. Dorothy's expertise spans
                        financial reporting, data analysis, and management, ensuring Regonow's fiscal health and sustainable growth.

                    </span>
                </div>

            </div>
        </div>
    )
}

export default Dorothy