import React from 'react';
import './policy.scss';

const Policy = () => {
    return (
        <div className='policy'>
            <h1>Privacy Policy</h1>
            <div className="policyBody">
                <span className='date'>Last updated: July, 2023</span>
                <span className='desc'>This Privacy Policy explains how your personal information is collected, used, stored,
                    and disclosed by Regonow Limited.

                    By using Regonow web app as Service, You agree to the collection and use of information
                    in accordance with this Privacy Policy. This Privacy Policy has been created with the help of the Privacy Policy Generator.

                    You are independently responsible for complying
                    with all applicable terms in all of your actions related to your use of Regonow Services, regardless of the purpose of the use.
                </span>
            </div>

            <div className="policyBody">
                <h2>Collection of Personal Data</h2>
                <span className='desc'>

                    When you use Regonow services such as register an account, post an inquiry or interact with us on our social media,
                    we collect personal information from you, which includes your name, email address, phone number, banking information, photo,
                    National Identification Number and employer's details (collectively, "Personal Information"). By providing your Personal Information and
                    any other kind of information or data to us, you expressly agree to our collection, use, storage, and disclosure of such
                    information as described in this Privacy Policy. As a User, we will also ask you to create login information for
                    your Regonow account, such as a username and password. When you provide your phone number, we may ask for your
                    consent to use this number for mobile money transactions as you operate your Regonow account. We collect your Personal Information
                    everytime you fill out a form on the Regonow web app or otherwise.
                    We receive, use, store and transfer Personal Information collected automatically when you consent by using Regonow services.

                </span>
            </div>

            <div className="policyBody">
                <h2>Use of Personal Data</h2>
                <span className='subHeading'>Regonow Limited may use your Personal Information for the following purposes:</span>

                <ul>
                    <li>To provide, maintain and monitor the usage of our Service.</li>
                    <li>To manage Your Account. The Personal Information You provide gives You access to different functionalities of the Service available to only registered users.</li>
                    <li>To provide You with news, special offers and general information about service updates, transactions and events unless You have opted not to receive such information.</li>
                    <li>To communicate or transfer fuctionality requests to Us.</li>
                    <li>For business transfers: We may use Your information to evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Our assets.</li>
                    <li>We may use Your information for data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns and to evaluate and improve our Service, marketing and your experience.</li>
                </ul>

                <span className='subHeading'>Regonow Limited may share your Personal Information for the following purposes:</span>

                <ul>
                    <li>With Service Providers: We may share Your Personal Information with Service Providers to monitor and analyze the use of our Service, to contact You.</li>
                    <li>For business transfers: We may share or transfer Your Personal Information in connection with, or during negotiations of, any merger, sale of Company assets, financing, or acquisition of all or a portion of Our business to another company.</li>
                    <li>With Affiliates: We may share Your information with Our affiliates, in which case we will require those affiliates to honor this Privacy Policy. Affiliates include subsidiaries, joint venture partners or other companies that We control or that are under common control with Us.</li>
                    <li>With business partners: We may share Your Personal Information with Our business partners to offer You certain products, services or promotions.</li>
                    <li>With other users: when You share Personal Information  or otherwise interact in the public areas with other users, such information may be viewed by all users and may be publicly distributed outside. If You interact with other users or register through a Third-Party Social Media Service, 
                        Your contacts on the Third-Party Social Media Service may see Your name, profile, pictures and description of Your activity. Similarly, other users will be able to view descriptions of Your activity, communicate with You and view Your profile.</li>
                    <li>With Your consent: We may disclose Your Personal Information  for any other purpose with Your consent.</li>
                </ul>

            </div>

            <div className="policyBody">
                <h2>Children's Policy</h2>
                <span className='desc'>
                Our Services are not directed or intended to be attractive, to children under the age of 18. We do not knowingly collect Personal Information from children under the age of 18; that is why it is mandatory to submit your National Indentification Number(NIN). 
                If you are under the age of 18, PLEASE do not use our Services or submit any information to us.
                If You are a parent or guardian and You are aware that Your child has provided Us with
                Personal Information, please contact Us. If We become aware that We have collected Personal Information from anyone under the age of 
                18, We take steps to remove that information from Our servers.
                We are building features that empower you to save for your child but until they are launched, this service is ONLY for adults.

                </span>
            </div>

            <div className="policyContact">
                <h2>Contact Us</h2>
                <span className='desc'>
                    If you have any questions about this Privacy Policy, You can contact us:
                </span>
                <div className='contact'>
                    <p>By email:</p>
                    <span>support@rego-now.com</span>
                </div>
            </div>


        </div>
    )
}

export default Policy