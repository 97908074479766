import React from 'react';
import './about.scss';
import { Link } from 'react-router-dom';


const About = () => {

    const scrollToTop = () => {
        window.scrollTo(0, 0)
    }


    return (
        <div className='aboutWrapper'>

            <div className='aboutSec2'>

                <div className='text'>
                    <span>OUR MISSION</span>

                    {/* <h1>To advance financial inclusion and collective action to
                        tackle poverty by providing simplified and affordable digital tools
                        for communities to save & invest easily.
                    </h1> */}

                    <h1>
                        To advance financial inclusion and collective action,
                        tackling poverty by providing accessible, affordable, and simplified digital
                        tools for communities to save and invest with ease.
                    </h1>
                </div>

                <div className='image'>
                    <div className="item">
                        <img src={process.env.PUBLIC_URL + '/ladies2.webp'} alt="" />
                        <img src={process.env.PUBLIC_URL + '/couple.webp'} alt="" />
                    </div>
                    <div className="item">
                        <div className='top'></div>
                        <img src={process.env.PUBLIC_URL + '/ladies1.webp'} alt="" />
                    </div>
                </div>

            </div>


            <div className='aboutSec3'>

                <div className="top">
                    <span>Our Founders</span>
                    <hr className='aboutHr' />
                </div>


                <div className="founders">
                    <div className="founderItem">

                        <img src={process.env.PUBLIC_URL + '/sue.png'} alt="" />

                        <div className="wrapper">
                            <div className="textWrapper">
                                <h3>Susan Hirego</h3>
                                <span>Co-Founder & CEO</span>
                                <Link to="/company/Susan-Hirego" className="link" onClick={scrollToTop}>
                                    <p>View full bio</p>
                                </Link>
                            </div>
                        </div>

                    </div>

                    <div className="founderItem">
                        <img src={process.env.PUBLIC_URL + '/rothy.png'} alt="" />
                        <div className="wrapper">
                            <div className="textWrapper">
                            <h3>Dorothy Nandinghi</h3>
                                <span>Co-Founder & CFO</span>
                                <Link to="/company/Dorothy-Nandinghi" className="link" onClick={scrollToTop}>
                                    <p>View full bio</p>
                                </Link>
                            </div>
                        </div>

                    </div>

                </div>











                {/* <div className='founders'>

                    <div className='founderImage'>

                        {/* <div className="item">
                            <img src={process.env.PUBLIC_URL + '/ladies2.webp'} alt="" />
                            <img src={process.env.PUBLIC_URL + '/couple.webp'} alt="" />
                        </div> */}

                {/* <div className="item">

                            <img src={process.env.PUBLIC_URL + '/ladies2.webp'} alt="" />
                            <img src={process.env.PUBLIC_URL + '/couple.webp'} alt="" />

                            <div className="founder1">
                                <img className='founderImg' src={process.env.PUBLIC_URL + '/sue2.webp'} alt="" />
                                <h3>Susan Hirego</h3>
                                <span>Co-Founder/CEO</span>
                                <Link to="/company/Susan-Hirego" className="link" onClick={scrollToTop}>
                                    <p>View full bio</p>
                                </Link>
                            </div>

                            <div className="founder2">
                                <img className='founderImg' src={process.env.PUBLIC_URL + '/rothy.webp'} alt="" />
                                <h3>Dorothy Nandinghi</h3>
                                <span>Co-Founder/CFO</span>
                                <Link to="/company/Dorothy-Nandinghi" className="link" onClick={scrollToTop}>
                                    <p>View full bio</p>
                                </Link>
                            </div>
                        </div> */}

            </div>

            {/* <div className="founder1">

                        <img src={process.env.PUBLIC_URL + '/sue2.webp'} alt="" />
                        <h3>Susan Hirego</h3>
                        <span>Co-Founder/CEO</span>
                        <Link to="/company/Susan-Hirego" className="link" onClick={scrollToTop}>
                            <p>View full bio</p>
                        </Link>

                    </div>

                    <div className="founder2">

                        <img src={process.env.PUBLIC_URL + '/rothy.webp'} alt="" />
                        <h3>Dorothy Nandinghi</h3>
                        <span>Co-Founder/CFO</span>
                        <Link to="/company/Dorothy-Nandinghi" className="link" onClick={scrollToTop}>
                            <p>View full bio</p>
                        </Link>

                    </div> */}

            {/* </div>  */}

        </div>



    )
}

export default About
