import React from 'react';
import './footer.scss';
import { FaFacebookF } from 'react-icons/fa';
import { MdOutlineCopyright } from 'react-icons/md';
import { GrInstagram, GrTwitter } from 'react-icons/gr';
import { TfiLocationPin, TfiEmail } from 'react-icons/tfi';
import { Link } from 'react-router-dom';

const Footer = () => {

    const scrollToTop = () => {
        window.scrollTo(0, 0)
    }

    return (
        <div className="mainFooter">

            <div className="footerTop">

                <div className="item">
                    <Link to="/about" className='link' onClick={scrollToTop}>
                        <span>About Us</span>
                    </Link>
                </div>

                <div className="item">
                    <Link to="/how-it-works" className='link' onClick={scrollToTop}>
                        <span>How It Works</span>
                    </Link>
                </div>

                <div className="item">
                    <Link to="/faqs" className='link' onClick={scrollToTop}>
                        <span>FAQs</span>
                    </Link>
                </div>

                <div className="item">
                    <Link to="/policy" className='link' onClick={scrollToTop}>
                        <span>Privacy Policy</span>
                    </Link>
                </div>

            </div>

            <div className='footerCenter'>
                <p>Regonow is registered as a Limited Liability Company under the name Rego Now
                    Limited. We are a financial technology company not a bank, all transactions
                    are handled by flutterwave and registred banking institutions. Regonow is a universal
                    savings platform  for all groups of people above the age of 18 however, each group should be women-led.
                </p>
            </div>

            <div className="hrWrapper">
                <hr />
            </div>


            <div className='footerBottom'>
                <div className="top">
                    <p>Call us on (+256) 775 326 157</p>
                    <p>Email: support@rego-now.com</p>
                </div>

                <div className="bottom">
                    <div className="left">
                        <MdOutlineCopyright  className='copyRight'/>
                        <span>2023</span>
                        <span>Rego Now Limited</span>
                        <span>|</span>
                        <span>All Rights Reserved</span>
                    </div>

                    <div className="right">
                        <span>Follow us:</span>
                        <a href="https://www.facebook.com/regonowapp" target='blank' className='a-link'>
                            <div className="rightItem">
                                <FaFacebookF className='icon' />
                            </div>

                        </a>
                        <a href="https://www.instagram.com/regonowapp/?hl=en" target='blank' className='a-link'>
                            <div className="rightItem">
                                <GrInstagram className='icon' />
                            </div>
                        </a>

                        <a href="https://twitter.com/regonowapp" target='blank' className='a-link'>
                            <div className="rightItem">
                                <GrTwitter className='icon' />
                            </div>
                        </a>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer