import * as React from 'react';
import './questions.scss';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    width: '100%',
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '1.1rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    padding: theme.spacing(1),
    color: "#003153",
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : '#f0f2f5',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(4),
    color: "#003153",
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function CustomizedAccordions() {
    const [expanded, setExpanded] = React.useState('panel1');

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    return (
        <div className='questionWrapper'>

            <div className='questionHeading'>
                <h3>Frequently Asked Questions (FAQs)</h3>
                <p>Below, we have answeared some questions for you to get to know us better...</p>
            </div>

            <div >

                <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                    <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                        <Typography>What is Regonow?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            Simplifying savings group management with automated tracking and monitoring solutions.
                        </Typography>
                        <Typography style={{ marginTop: '20px' }}>
                            Regonow is a cutting-edge financial technology company dedicated to empowering
                            savings groups through innovative digital solutions. Our mission is to drive
                            financial inclusion and collective prosperity by providing accessible, user-friendly,
                            and affordable digital tools for communities to save, invest, and thrive. By
                            streamlining savings management, we aim to unlock economic opportunities and
                            tackle poverty, fostering a brighter financial future for all.
                        </Typography>
                    </AccordionDetails>
                </Accordion>

                <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                    <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                        <Typography>Is Regonow free?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            Try Regonow today at no cost! Our onboarding process is free, and we charge a
                            minimal monthly fee per group to help us continue to provide our services.
                        </Typography>
                    </AccordionDetails>
                </Accordion>

                <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                    <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                        <Typography>Is Regonow a bank?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            No, we're a financial technology company registered as REGO NOW LIMITED.
                            We partner with a licensed bank to provide secure and reliable banking
                            services for all our transactions. While we're not a bank, our partnership
                            ensures your funds are safeguarded and managed with utmost care.
                        </Typography>
                    </AccordionDetails>
                </Accordion>

                <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                    <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
                        <Typography>What are the requirements for joining Regonow?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography >
                            To join Regonow, your savings group must meet the following requirements:
                        </Typography>
                        <Typography style={{ marginTop: '20px' }}>
                            - A binding agreement (endagano) among members
                        </Typography>
                        <Typography style={{ marginTop: '5px' }}>
                            - Access to a smartphone or computer with internet connection for the group administrator to record transactions
                        </Typography>
                        <Typography style={{ marginTop: '5px' }}>

                            - Each member must have a valid National Identification Number (NIN) or passport for authentication

                        </Typography>
                        <Typography></Typography>
                    </AccordionDetails>
                </Accordion>

                <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                    <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                        <Typography>What types of savings groups can join Regonow?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                        Various types of savings groups can join Regonow, including informal savings groups,
                        formal savings cooperatives, workplace savings groups, community-based savings groups,
                         women's savings groups, youth savings groups, agricultural savings groups, and small business savings groups.
                        </Typography>
                    </AccordionDetails>
                </Accordion>

                <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                    <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                        <Typography>How does Regonow ensure the security and privacy of members' personal and financial information?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                        Regonow prioritises security and privacy, using robust encryption,
                        secure servers, and strict access controls to protect members' personal
                        and financial information. They also comply with relevant data protection
                        regulations and ensure that sensitive information is never shared with third parties without consent.
                        </Typography>
                    </AccordionDetails>
                </Accordion>

                <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
                    <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                        <Typography>What features does Regonow offer to help savings groups manage their finances effectively?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                        Regonow offers features like transaction tracking, budgeting tools, savings goals, and financial reporting
                        to help savings groups manage their finances effectively. They also provide resources for financial
                        education and literacy.
                        </Typography>
                    </AccordionDetails>
                </Accordion>

                <Accordion expanded={expanded === 'panel8'} onChange={handleChange('panel8')}>
                    <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                        <Typography>How does Regonow promote financial literacy and education among its members?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                        Regonow offers financial literacy resources, workshops, and training programs to
                        educate members on personal finance, budgeting, and savings best practices,
                        empowering them to make informed financial decisions.
                        </Typography>
                    </AccordionDetails>
                </Accordion>

                <Accordion expanded={expanded === 'panel9'} onChange={handleChange('panel9')}>
                    <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                        <Typography>Can individual members access their savings information and transaction history on Regonow?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                        Yes, individual members can access their savings information and transaction history on Regonow, including their personal savings in their individual wallets, allowing them to stay informed and up-to-date on their savings progress.

                        </Typography>
                    </AccordionDetails>
                </Accordion>

                <Accordion expanded={expanded === 'panel10'} onChange={handleChange('panel10')}>
                    <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                        <Typography>How does Regonow facilitate payments and withdrawals for members?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                        Regonow facilitates payments and withdrawals through FLUTTERWAVE which accommodates various channels, including mobile money, bank transfers, and card payments, making it easy for members to deposit and withdraw funds from their group accounts and individual wallets.


                        </Typography>
                    </AccordionDetails>
                </Accordion>

                <Accordion expanded={expanded === 'panel11'} onChange={handleChange('panel11')}>
                    <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                        <Typography>What kind of customer support does Regonow offer to savings groups and members?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                        Regonow provides dedicated customer support through various channels, including phone, email, and in-app support, ensuring that savings groups and members receive assistance when needed.
                        </Typography>
                    </AccordionDetails>
                </Accordion>


            </div>

        </div>
    );
}
