import React, { useEffect, useRef } from 'react';
// import './Home.css';
import './home.scss';
import { FaArrowRight, FaApple, FaGooglePlay } from 'react-icons/fa';
import { GrAssistListening } from "react-icons/gr";
import { GiTeacher } from "react-icons/gi";
import { SiDavinciresolve } from "react-icons/si";
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);



const Home = () => {

    const animateRef = useRef(null);
    const animateRef1 = useRef(null);
    const animateRef2 = useRef(null);

    useEffect(() => {
        const el = animateRef.current;
        gsap.fromTo(el, { y: 55, opcaity: 0.2, }, {
            duration: 0.5, delay: 0.5, y: 0, stagger: 0.1, scrollTrigger: {
                trigger: el,

            }
        });
    }, [])

    useEffect(() => {
        const el1 = animateRef1.current;
        gsap.fromTo(el1, { y: 15 }, {
            duration: 1, delay: 0.5, y: 0, stagger: 0.15, scrollTrigger: {
                trigger: el1,

            }
        });
    }, [])

    useEffect(() => {
        const el1 = animateRef2.current;
        gsap.fromTo(el1, { y: 15 }, {
            duration: 1, delay: 0.5, y: 0, stagger: 0.17, scrollTrigger: {
                trigger: el1,

            }
        });
    }, [])

    // useEffect(() => {
    //     const el1 = animateRef3.current;
    //     gsap.fromTo(el1, { y: 15 }, {
    //         duration: 1, delay: 0.5, opcaity: 0.7, y: 0, stagger: 0.19, scrollTrigger: {
    //             trigger: el1,

    //         }
    //     });
    // }, [])




    return (

        <div className='homeWrapper'>

            <div className='sec1'>

                <div className='sec1Left' ref={animateRef}>
                    <h1>Build Your</h1>
                    {/* <h1>Simplifying Savings Group Management</h1> */}
                    <h1>Wealth in Groups</h1>
                    <p>
                        Regonow streamlines the way savings groups keep and
                        monitor their funds by automating saving.
                    </p>

                    {/* <p>
                        We streamline savings group management, making it effortless to track
                        and maintain funds with our automated savings solution.
                    </p> */}

                    <a

                        href="https://members.rego-now.com/register"
                        target="_blank" rel="noreferrer"
                        className='homeBtn1'
                        // ref={animateRef2}
                        style={{ textDecoration: 'none', color: 'white' }}
                    >
                        Register your Group
                    </a>
                </div>

                <div className='sec11Left'>
                    <h1 ref={animateRef1}>Build Your</h1>
                    <h1 ref={animateRef1}>Wealth in Groups</h1>
                    {/* <h1  ref={animateRef1}>Simplifying Savings</h1>
                    <h1 ref={animateRef1} style={{marginTop:'-10px'}}>Group Management</h1> */}
                    <p>
                        Regonow streamlines the way savings groups keep and
                        monitor their funds by automating saving.
                    </p>
                    <a

                        href="https://members.rego-now.com/register"
                        target="_blank" rel="noreferrer"
                        className='homeBtn1'
                        ref={animateRef2}
                        style={{ textDecoration: 'none', color: 'white' }}
                    >
                        Register your Group
                    </a>
                </div>

                <div className='sec1Right' >
                    <div className='imgWrapper'>
                        <img src={process.env.PUBLIC_URL + '/sharon.webp'} alt="" />
                    </div>
                </div>

            </div>


            <div className='sec2'>

                <div className="sec2Top">

                    {/* <span className='subHeading'>Empowering Savings Groups to Thrive</span> */}

                    <h1>Simplifying Savings Group</h1>
                    <h1 style={{ marginTop: '-5px' }}>Management</h1>

                    <p style={{ marginBottom: 5 }}>
                        {/* We support savings groups to optimise their efficiency through a simplied digital savings systems.
                        A community of women committed
                        to improving their living conditions by saving and taking
                        up development projects together. */}

                        {/* Our platform is designed to enhance the efficiency and transparency of savings groups,
                        ensuring that members can easily monitor their contributions, withdrawals, and overall
                        financial health. */}
                        Designed to enhance the efficiency and transparency of savings groups,
                        ensuring that members can easily monitor their contributions, withdrawals, and overall
                        financial health.
                    </p>

                </div>

                <div className="bottomWrapper">
                    <div className="sec2Bottom">
                        <img src={process.env.PUBLIC_URL + '/savingGroup.png'} alt="" />
                    </div>
                </div>

            </div>


            <div className='sec3'>

                <div className="sec3Left">
                    <h1>Give your family, friends
                        and community access to a brighter future
                    </h1>

                    <div className="bottom">

                        <a
                            href="https://members.rego-now.com/register"
                            target="_blank" rel="noreferrer"
                            className='linkText'
                            style={{ textDecoration: 'none', color: 'inherit', display: "flex", alignItems: 'center' }}
                        >
                            <p>Explorer saving benefits</p>
                            <FaArrowRight className='bottomIcon' />

                        </a>

                    </div>
                </div>

                <div className='image'>
                    <div className="item">
                        <img src={process.env.PUBLIC_URL + '/family.webp'} alt="" />
                        <img src={process.env.PUBLIC_URL + '/group2.webp'} alt="" />
                    </div>
                    <div className="item">
                        <div className='top'></div>
                        <img src={process.env.PUBLIC_URL + '/friends.webp'} alt="" />
                    </div>
                </div>

            </div>


            <div className='notify'>
                <div className='notifyLeft'>
                    <div className='imgWrapper'>
                        <img src={process.env.PUBLIC_URL + '/notify.png'} alt="" />
                    </div>
                </div>
                <div className='notifyRight'>
                    <div className='notifyTop'>
                        <h1>Real-Time Updates</h1>
                        <h3>,</h3>
                        <h2>Stay Informd!</h2>
                    </div>

                    <p style={{ marginBottom: 5 }}>
                        Stay on top of your finances with real-time alerts! Receive
                        instant notifications for balance updates and transactions,
                        keeping you informed and in control.
                    </p>

                </div>
            </div>


            <div className='points'>

                <div className="leftPoints">
                    <div className='colorTextWrapper'>
                        <h1>Get</h1>
                        <span className='colorText'>Cash Back</span>
                    </div>
                    <h1>With Rego Points</h1>

                    {/* <h2>Get cash back with Rego Points</h2> */}

                    <div className='colorTextWrapper2'>
                        <h2>Get</h2>
                        <span className='colorText2'>Cash Back</span>
                        <h2>with Rego Points</h2>
                    </div>

                    <p>
                        Earn points on each transaction you make!
                        Rego Points is a loyalty program for all group
                        members. Points are earned on each savings deposit and loan repayment
                        transaction.
                    </p>
                </div>

                <div className="rightpoints">

                    <div className='top'>
                        <img className="pointscoins" src={process.env.PUBLIC_URL + '/coins.webp'} alt="" />
                        <img className="depositpoints" src={process.env.PUBLIC_URL + '/loanpoints.webp'} alt="" />
                    </div>

                    <div className='bottom'>
                        <img className="loanpoints" src={process.env.PUBLIC_URL + '/stars.webp'} alt="" />
                    </div>

                </div>

            </div>


            <div className='sec5Wrapper'>

                <h1>Our Approach</h1>

                <div className="approach">


                    <div className="listen">
                        <GrAssistListening className='listenIcon' />
                        <h3>We Listen</h3>
                        <p>
                            We understand the unique financial challenges faced by unbanked and
                            underbanked communities and are dedicated to providing inclusive solutions for all
                        </p>
                    </div>

                    <div className="resolve">
                        <SiDavinciresolve className='resolveIcon' />
                        <h3> We Resolve</h3>
                        <p>
                            We offer flexible repayment options and personalised support to help
                            group members overcome financial setbacks and avoid debt traps.
                        </p>
                    </div>

                    <div className="empower">
                        <GiTeacher className='empowerIcon' />
                        <h3>We Empower</h3>
                        <p>
                            We equip members with financial literacy skills, enabling
                            them to make informed decisions and achieve long-term financial stability.

                        </p>
                    </div>

                </div>

            </div>

            <div className='sec6Wrapper'>

                <div className='left'>
                    <div className='imgWrapper'>
                        <img src={process.env.PUBLIC_URL + '/goalss.webp'} alt="" />
                    </div>
                </div>

                <div className="right">

                    <h1> Launching Soon!</h1>

                    <div className='action'>
                        <span>Start saving with a community.</span>
                        <span>Join hundreds of groups using Regonow today!</span>
                    </div>

                    <div className="buttons">

                        <div className='appleBtn'>
                            <FaApple className='icon' />
                            <div className="text">
                                <p>Download on the</p>
                                <h3>App Store</h3>
                            </div>
                        </div>

                        <div className='playBtn'>
                            <FaGooglePlay className='icon2' />
                            <div className="text">
                                <p>GET IT ON </p>
                                <h3>Google Play</h3>
                            </div>
                        </div>

                    </div>

                </div>

            </div>

        </div>

    );
};

export default Home;
