import React from 'react';
import './process.scss';
import Wave from 'react-wavify';

import { GiArchiveRegister } from "react-icons/gi";
import { FaPeopleGroup } from "react-icons/fa6";
import { PiHandDepositFill } from "react-icons/pi";
import { GiTakeMyMoney } from "react-icons/gi";
import { GrTransaction } from "react-icons/gr";
import { PiHandWithdrawFill } from "react-icons/pi";


const Process = () => {
    return (
        <div className='process'>

            <div className='work'>

                <div className="workTop">

                    {/* <span className='subHeading'>Empowering Savings Groups to Thrive</span> */}

                    <h1>Connect With Others</h1>
                    <h1>Grow your Savings!</h1>

                    <p style={{ marginBottom: 5 }}>
                        Traditional savings groups face many challenges. Regonow's digital
                        solution streamlines management, reduces errors, and increases
                        transparency. We are dedicated to empowering savings groups through innovative digital solutions.
                    </p>

                </div>

                <div className="workBottom">
                    <div className="sec2Bottom">
                        <img src={process.env.PUBLIC_URL + '/myAcc.png'} alt="" />
                    </div>
                </div>

            </div>


            <div className='processes'>

                <div className='processHeading'>
                    <h3>HOW IT WORKS</h3>

                    <h1>Empowering Savings Groups to Thrive</h1>

                    <h2>Empowering Savings</h2>
                    <h2 style={{marginTop:'-12px'}}>Groups to Thrive</h2>

                    <span>
                    Regonow simplifies savings group management with automated tracking and monitoring solutions.
                    Our mission is to drive financial inclusion
                    and collective prosperity by providing accessible, user-friendly, and affordable digital
                    tools for communities to save, invest, and thrive. By streamlining savings management,
                    we aim to unlock economic opportunities and tackle poverty, fostering a brighter financial
                    future for all.
                    </span>
                </div>


                <div className='processWrapper1'>

                    <div className='processItem'>
                        <GiArchiveRegister className='processIcon' />
                        <p>STEP ONE</p>
                        <h1>Sign Up</h1>

                        <span>
                            Create an account on Regonow website or mobile app. All fields are required, ensure you
                            provide the right and valid email, phone number and National ID (NIN)
                        </span>

                    </div>

                    <div className='processItem'>
                        < FaPeopleGroup className='processIcon' />
                        <p>STEP TWO</p>
                        <h1>Create a group / Invite Members</h1>

                        <span>
                            Create a new group by searching for already registered users to add. A group should
                            have a minimum of 4 members including the group Admin (the creator). If you wish to
                            join an existing group, contact its group Admin to add.
                        </span>

                    </div>

                    <div className='processItem'>
                        <PiHandDepositFill className='processIcon' />
                        <p>STEP THREE</p>
                        <h1>Deposit Funds</h1>

                        <span>
                            Each member has an individual wallet where funds are deposited using mobile money, visa and
                            mastercard. A success report for deposited funds will pop up as soon as you confirm the
                            transaction.
                        </span>

                    </div>

                </div>

                <div className='processWrapper1' style={{marginTop:'-40px'}}>

                    <div className='processItem'>
                        <GiTakeMyMoney className='processIcon' />
                        <p>STEP FOUR</p>
                        <h1>Contribute to Group Savings</h1>

                        <span>
                            A user can belong to one or more groups; you decide the amount to save in each group
                            or as stated in your groups' bylaws.
                        </span>

                    </div>

                    <div className='processItem'>
                        < GrTransaction className='processIcon' />
                        <p>STEP FIVE</p>
                        <h1>Track Progress</h1>

                        <span>
                            Monitor your group's savings progress,transaction history and individual contributions
                            on the Regonow dashboard.
                        </span>

                    </div>

                    <div className='processItem'>
                        <PiHandWithdrawFill className='processIcon' />
                        <p>STEP SIX</p>
                        <h1>Withdraw Funds</h1>

                        <span>
                            Only the group Admin can access the withdraw page for a specified group.
                            If you wish to withdraw funds, contact your group admin to initiate the transaction.
                        </span>

                    </div>

                </div>

            </div>



        </div>
    )
}

export default Process