import React from 'react';
import './founders.scss';
import { FaLinkedin } from 'react-icons/fa';

const Susan = () => {
	return (
		<div className='susanWrapper'>

			<div className="wrapper">
				<div className="top">
					<img className='imageTwo' src={process.env.PUBLIC_URL + '/sue.png'} alt="" />

					<div className="text">
						<h3>Susan Hirego</h3>
						<span>Co-founder & CEO</span>
						<a
							href="https://www.linkedin.com/in/susanhirego/"
							target="_blank" rel="noopener noreferrer"
							className='link'
						>
							< FaLinkedin className="founderIcon" />
						</a>
					</div>
				</div>
				<div className="bottom">
					<span>
						Meet Susan Hirego, the visionary Co-founder and CEO of Regonow. With a strong engineering background,
						Susan drives the technical vision and strategy, overseeing product development, engineering, design,
						and software development.
					</span>

					<span>
						As a dedicated social entrepreneur, she also founded and leads Rego Foundation,
						which has empowered over 1,200 young women with financial literacy training. Susan's operational expertise
						and passion for excellence have yielded impressive results. She is dedicated to unlocking individual potential
						and driving success through innovative solutions and inclusive financial education.
					</span>
				</div>

			</div>
		</div>
	)
}

export default Susan