import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import './App.css';
import About from './Pages/about/About';
import Questions from './Pages/questions/Questions';
import Home from './Pages/home/Home';
import Navbar from './Components/navbar/Navbar';
import Footer from './Components/footer/Footer';
import Dorothy from './Pages/founders/Dorothy';
import Susan from './Pages/founders/Susan';
import Process from './Pages/process/Process';
import Policy from './Pages/policy/Policy';





function App() {

    return (

        <div className="app">

            <Router>
                <Switch>

                    <>
                        <Navbar/>

                        <div  className='container'>
                            <Route exact path='/' component={Home} />
                            <Route path='/faqs' component={Questions} />
                            <Route path='/about' component={About} />
                            <Route path='/company/Dorothy-Nandinghi' component={Dorothy} />
                            <Route path='/company/Susan-Hirego' component={Susan} />
                            <Route path='/how-it-works' component={Process} />
                            <Route path='/policy' component={Policy} />
                        </div>

                        <Footer/>

                    </>

                </Switch>
            </Router>

        </div>

    );
}

export default App;
