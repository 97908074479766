export const navItems = [

    {
        id: 1,
        title: 'Home',
        path: '/',
        cName: 'listItem',
    },
    {
        id: 2,
        title: 'About us',
        path: '/about',
        cName: 'listItem',
        // children: [
        //     {
        //         id: 1,
        //         title: 'Who We Are',
        //         path: '/about',
        //         cName: 'dropdown-link'
        //     },
        //     {
        //         id: 2,
        //         title: 'Our Team',
        //         path: '/team',
        //         cName: 'dropdown-link'
        //     },
        //     {
        //         id: 3,
        //         title: 'Impact Stories',
        //         path: '/impact-stories',
        //         cName: 'dropdown-link'
        //     },

        // ]
    },
    {
        id: 3,
        title: 'How It Works',
        path:  '/how-it-works',
        cName: 'listItem',
        // children: [
        //     {
        //         id: 1,
        //         title: 'SheBoss Program',
        //         path: '/sheboss-entreprenuership',
        //         cName: 'dropdown-link'
        //     },
        //     {
        //         id: 2,
        //         title: 'Regonow - Group Savings',
        //         path: '/regonow-savings',
        //         cName: 'dropdown-link'
        //     },
        //     {
        //         id: 3,
        //         title: 'Tech Scholars Program',
        //         path: '/tech-scholars',
        //         cName: 'dropdown-link'
        //     },

        // ]
    },
    {
        id: 4,
        title: 'FAQs',
        path: '/faqs',
        cName: 'listItem',
        // children: [
        //     {
        //         id: 1,
        //         title: 'Volunteer with us',
        //         path: '/volunteer-with-us',
        //         cName: 'dropdown-link'
        //     },
        //     {
        //         id: 2,
        //         title: 'Become a Mentor',
        //         path: '/mentor-a-developer',
        //         cName: 'dropdown-link'
        //     },
        //     {
        //         id: 3,
        //         title: 'Host a Dev Intern',
        //         path: '/host-an-itern',
        //         cName: 'dropdown-link'
        //     },
        // ]

    },


];










