/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect } from 'react';
import { useState } from "react";
import "./navbar.scss";
import { FaBars, FaTimes, FaPlus, FaMinus } from "react-icons/fa";
import { Link } from 'react-router-dom';
import { navItems } from './menus/menuItems';
import NavMenus from './NavMenus';

import { SlHome } from "react-icons/sl";
import { GoBook } from "react-icons/go";
import { GoWorkflow } from "react-icons/go";
import { AiOutlineQuestionCircle } from "react-icons/ai";



const Navbar = () => {

    const scrollToTop = () => {
        window.scrollTo(0, 0)
    }

    const [open, setOpen] = useState(false);


    return (
        <div className="navbar active">

            <div className="wrapper">

                <div className="left">
                    <Link to="/" className="link">
                        <div className='logoWrapper'>
                            <img className="logoImage" src="/newlogo.png" alt="logo" />
                            <span className="logoText">regonow</span>
                        </div>
                    </Link>
                </div>

                <div className="right">

                    <div className="navbar-links">
                        <ul className="navList">
                            {navItems.map((item) => (
                                <NavMenus key={item.id} item={item} />
                            ))}
                        </ul>
                    </div>

                    <div className="buttons">

                        <a
                            href="https://members.rego-now.com/login"
                            target="_blank" rel="noreferrer"
                            className="nav-links-mobile"
                            style={{ textDecoration: 'none', color: 'inherit' }}
                        >
                            <button className='navBtn'>Login</button>

                        </a>

                        <a
                            href="https://members.rego-now.com/register"
                            target="_blank" rel="noreferrer"
                            className="nav-links-mobile"
                            style={{ textDecoration: 'none', color: 'inherit' }}
                        >
                            <button className='navBtn2'>Register</button>

                        </a>

                    </div>


                </div>

            </div>


            <div className="hamburger" onClick={() => setOpen(!open)}>
                {open ? <FaTimes className="icon" /> : <FaBars className="icon" />}
            </div>

            <ul className="menu" style={{ right: open ? "0px" : "-100vw" }}>

                <li className="menuItem" onClick={() => setOpen(false)}>
                    <Link to="/" className='link' onClick={scrollToTop}>
                        <SlHome className='resIcon'/>
                        <span>Home</span>
                    </Link>
                </li>

                <li className="menuItem" onClick={() => setOpen(false)}>
                    <Link to="/about" className='link' onClick={scrollToTop}>
                        <GoBook className='resIcon'/>
                        <span>About</span>
                    </Link>
                </li>

                <li className="menuItem" onClick={() => setOpen(false)}>
                    <Link to="/how-it-works" className='link' onClick={scrollToTop}>
                        <GoWorkflow className='resIcon'/>
                        <span>How It Works</span>
                    </Link>
                </li>

                <li className="menuItem" onClick={() => setOpen(false)}>
                    <Link to="/faqs" className='link' onClick={scrollToTop}>
                        <AiOutlineQuestionCircle className='resIcon'/>
                        <span>FAQs</span>
                    </Link>
                </li>

                <div className="res-buttons">
                    <li>
                        <a
                            href="https://members.rego-now.com/login"
                            target="_blank" rel="noreferrer"
                            className="nav-links-mobile"
                            style={{ textDecoration: 'none', color: 'inherit' }}
                        >
                            <button className='navBtn'>Login</button>

                        </a>
                    </li>

                    <li>
                        <a
                            href="https://members.rego-now.com/register"
                            target="_blank" rel="noreferrer"
                            className="nav-links-mobile"
                            style={{ textDecoration: 'none', color: 'inherit' }}
                        >
                            <button className='navBtn2'>Register</button>

                        </a>
                    </li>

                </div>


            </ul>

        </div>
    );
};

export default Navbar;